import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import XLSX from 'xlsx';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import ActionButton from '../../lib/forms/ActionButton';
import SubmitButton from '../../lib/forms/SubmitButton';
import FileDragDropUploader from '../../lib/forms/FileDragDropUploader';
import ExcelSheetInput from '../../lib/forms/ExcelSheetInput';

import {
  allColumns,
  actionItemDataColumns,
  actionItemDataRows,
  actionItemsToExcel
} from '../../lib/data/action-items-workbook-parser';

import {
  convertDateFields
} from '../../lib/data/parser-helpers';

import {
  useUploadActionItemsMutation
} from '../../lib/ascertis-api';

const ActionItemsUploadForm = ({ system }) => {
  const history = useHistory();
  const [savedText, setSavedText] = useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [uploadItems] = useUploadActionItemsMutation();
  const [uploadedData, setUploadedData] = useState(undefined);

  const onUpload = (fileAsBinaryString) => {
    const workbook = XLSX.read(fileAsBinaryString, { type: 'binary' });
    const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
    let data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
    data = convertDateFields(data, ['Projected Completion', 'Actual Completion']);
    setUploadedData(data);
  };

  const handleSubmit = async (formValues, formActions) => {
    setProcessing(true);
    await uploadItems({ systemId: system.id, params: formValues }).unwrap()
      .then((payload) => {
        setProcessing(false);
        setSuccess(true);
        setError(false);
        setSavedText('POAM Items successfully uploaded.');
      }).catch((error) => {
        setProcessing(false);
        setSuccess(false);
        setError(true);
        setSavedText('Error uploading POAM Items.');
      });
  };

  const handleBack = () => {
    history.push(`/systems/${system.id}/action_plan`);
  };

  let initList = [];
  let columns = allColumns();

  let dataArea = (
    <div>Please Upload an Excel Sheet</div>
  );

  if (uploadedData) {
    columns = actionItemDataColumns(uploadedData);
    initList = actionItemDataRows(uploadedData);
  } else if (!initList){
    initList = [];
  }

  const Schema = Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        control: Yup.string().required(),
        requirement: Yup.string().required(),
      })
    )
  });

  let savedTextSection = undefined;
  if (savedText.length > 0) {
    savedTextSection = <div><br/>{savedText}<br/></div>;
  }

  console.log('COLUMNS', columns);

  const formArea = (
    <div className="form-area">
      <Row>
        <Column small={1}></Column>
        <Column small={10}>
          <Row>
            <Column small={6}>
              XLSX Uploads support files with columns labeled "Item ID", "Domain", "Control Number", "Control Category", "Control Type", "Control", "Requirement", "Status", "Remediation", "Resources Needed", "Projected Completion", and "Actual Completion"
            </Column>
            <Column small={6}>
              <FileDragDropUploader onUpload={onUpload} />
            </Column>
          </Row>
        </Column>
        <Column small={1}></Column>
      </Row>
      <Row>
        <Column small={12}>
          <FormContainer title="Action Items">
            <Formik
              initialValues={{
                items: initList
              }}
              validationSchema={Schema}
              validateOnChange
              enableReinitialize
              onSubmit={(values, actions) => {
                return handleSubmit(values, actions);
              }}
            >
              {(props) => (
                <Form role="form">
                  <Row>
                    <Column small={12}>
                      <div className="divider" />
                      <div className="section">
                        <Row>
                          <Column small={12}>
                            <Field
                              name="items"
                              component={ExcelSheetInput}
                              columns={columns}
                              onChange={props.setFieldValue}
                            />
                          </Column>
                        </Row>
                      </div>
                    </Column>
                  </Row>
                  <Row>
                    <Column small={12}>
                      {savedTextSection}
                    </Column>
                  </Row>
                  <Row className="form-button-container right-align">
                    <Column small={8}></Column>
                    <Column small={2}>
                      <ActionButton className="btn delete-button grey" onClick={handleBack} buttonText="Cancel" />
                    </Column>
                    <Column small={2}>
                      <SubmitButton>Submit</SubmitButton>
                    </Column>
                  </Row>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </Column>
      </Row>
    </div>
  );

  const processingArea = (
    <div className="processing-area">
      <div className="icon-wrapper center-align">
        <h3>Processing data...</h3>
        <i className="large material-icons">
          av_timer
        </i>
      </div>
    </div>
  )

  const successArea = (
    <div className="processing-area">
      <div className="icon-wrapper center-align">
        <h3>Successfully Updated!</h3>
        <i className="large material-icons">
          check_box
        </i>
      </div>

      <div className="link-area center-align">
        <ActionButton className="btn" onClick={handleBack} buttonText="Return to Action Plan" />
      </div>
    </div>
  )

  const errorArea = (
    <div className="processing-area">
      <div className="icon-wrapper center-align">
        <h3>Data could not be imported</h3>
        <i className="large material-icons">
          error_outline
        </i>
      </div>

      <div className="link-area center-align">
        <ActionButton className="btn" onClick={handleBack} buttonText="Return to Action Plan" />
      </div>
    </div>
  )

  let displayArea = formArea;
  if (processing) {
    displayArea = processingArea;
  } else if (error) {
    displayArea = errorArea;
  } else if (success) {
    displayArea = successArea;
  }

  return (
    <div id="upload-action-items-form">
      {displayArea}
    </div>
  );
};

ActionItemsUploadForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired
};

ActionItemsUploadForm.defaultProps = {};

export default ActionItemsUploadForm;
