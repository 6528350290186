import React from 'react';
import PropTypes from 'prop-types';

import Row from './Row';
import Column from './Column';

const classNames = require('classnames');

const Footer = ({ className, ...props }) => {
  const footerClasses = classNames({
    'page-footer': true,
    [`${className}`]: className
  });

  return (
    <footer className={footerClasses}>
      <div className="container">
        <Row>
          <Column small={12} medium={8} large={8}>
            <h5 className="white-text">ASCERTIS SOLUTIONS</h5>
          </Column>
          <Column small={12} medium={4} large={4}>
            <ul>
              <li><i className="tiny material-icons">phone</i> 703-307-8665</li>
              <li><i className="tiny material-icons">email</i> support@ascertis.solutions</li>
            </ul>
          </Column>
        </Row>
      </div>
      <div className="footer-copyright black">
        <div className="container">
          © 2023 ASCERTIS SOLUTIONS
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

Footer.defaultProps = {
  className: null
};

export default Footer;
