import React from 'react';
import Column from '../../lib/layout/Column';
import Section from '../../lib/layout/Section';

import NewSystemProvisionRequestForm from './NewSystemProvisionRequestForm';

const SystemProvisionRequestsPage = () => {
  const formArea = <NewSystemProvisionRequestForm />;

  return (
    <div id="solution_partners">
      <Column small={12}>
        <Section>
          <h5>Solution Partners</h5>
        </Section>
        <Section>
          {formArea}
        </Section>
      </Column>
    </div>
  );
};

SystemProvisionRequestsPage.propTypes = {
};

SystemProvisionRequestsPage.defaultProps = {
};

export default SystemProvisionRequestsPage;
