import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import Page from '../../lib/layout/Page';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';

import PublicLayout from '../Layout/PublicLayout';
import HomePage from '../Home/HomePage';
import SystemProvisionRequestsPage from '../SystemProvisionRequests/SystemProvisionRequestsPage';

import AccountLinks from '../User/AccountLinks';
import EmailSignInForm from '../User/EmailSignInForm';
import PasswordResetForm from '../User/PasswordResetForm';
import PasswordResetInvalidLink from '../User/PasswordResetInvalidLink';
import PasswordChangeForm from '../User/PasswordChangeForm';
import UnlockRequestForm from '../User/UnlockRequestForm';
import NotFound from '../Error/NotFound';

const UnauthenticatedRouter = () => {
  return (
    <Switch>
      <Route exact path="/">
        <PublicLayout transparent>
          <HomePage />
        </PublicLayout>
      </Route>

      <Route exact path="/home">
        <PublicLayout transparent>
          <HomePage />
        </PublicLayout>
      </Route>

      {/*<Route exact path="/get_started">*/}
      {/*  <PublicLayout transparent>*/}
      {/*    <SystemProvisionRequestsPage />*/}
      {/*  </PublicLayout>*/}
      {/*</Route>*/}

      <Route path="/user">
        <PublicLayout>
          <Page>
            <Row>
              <Column small={4}>
                <AccountLinks />
              </Column>
              <Column small={8}>
                <Switch>
                  <Route exact path="/user/login">
                    <EmailSignInForm />
                  </Route>

                  <Route exact path="/user/unlock">
                    <UnlockRequestForm />
                  </Route>

                  <Route exact path="/user/password">
                    <PasswordResetForm />
                  </Route>

                  <Route exact path="/user/password/edit">
                    <PasswordChangeForm />
                  </Route>

                  <Route exact path="/user/error/password_reset">
                    <PasswordResetInvalidLink />
                  </Route>

                  <Route>
                    <EmailSignInForm />
                  </Route>
                </Switch>
              </Column>
            </Row>
          </Page>
        </PublicLayout>
      </Route>

      <Route>
        <PublicLayout>
          <NotFound />
        </PublicLayout>
      </Route>
    </Switch>
  );
};

export default withRouter(UnauthenticatedRouter);
